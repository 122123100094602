import { readonly, computed } from 'vue';
import { defineStore } from 'pinia';
import type { Project, LegacyCaptions, Captions } from '@/areas/editor/@type/Project';
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore';
import { useLayoutsStore } from '@/areas/editor/store/useLayoutsStore';
import { useCropsStore } from '@/areas/editor/store/useCropsStore';
import { useStickersStore } from '@/areas/editor/store/useStickersStore';
import { useEffectsStore } from '@/areas/editor/store/useEffectsStore';
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo';
import { version } from '@/data/versions';
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions';
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore';
import { useVideoStore } from '@/areas/editor/store/useVideoStore';
import { useFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled';

export const useProjectStore = defineStore('project', () => {
  
  const editorClipInfoStore = useEditorClipInfoStore();

  const id = computed(() => editorClipInfoStore.id);
  const language = computed(() => editorClipInfoStore.languageCode);
  const title = computed(() => editorClipInfoStore.title);
  const mp4Url = computed(() => editorClipInfoStore.mp4Url);

  const segmentsStore = useSegmentsStore();
  const layoutsStore = useLayoutsStore();
  const cropsStore = useCropsStore();
  const stickersStore = useStickersStore();
  const effectsStore = useEffectsStore();
  const captionsStore = useCaptionsStore();

  const legacyCaptionsStore = useEditorCaptionsStore();
  const captionsV2Enabled = useFeatureFlagEnabled('new-captions');
  const useLegacyCaptions = computed(() => {
    if (legacyCaptionsStore.captionsDocument !== null) {
      return true;
    } else if (captionsStore.entities.length > 0) {
      return false;
    } else {
      return captionsV2Enabled.value !== true;
    }
  });

  return {

    useLegacyCaptions, 

    project: computed(() => {

      if (!id.value) {
        return null;
      }
      
      return readonly({
        id: id.value,
        title: title.value,
        language: language.value,
        mp4Url: mp4Url.value,
        version: version,
        crops: cropsStore.entities,
        segments: segmentsStore.entities,
        layouts: layoutsStore.entities,
        stickers: stickersStore.entities,
        effects: effectsStore.entities,
        captions: useLegacyCaptions.value ? {
          captions: legacyCaptionsStore.captions,
          document: legacyCaptionsStore.captionsDocument,
          settings: legacyCaptionsStore.captionsSettings,
          wrapper: legacyCaptionsStore.captionsWrapper,
        } as LegacyCaptions : {
          entities: captionsStore.entities,
          hasGeneratedCaptions: captionsStore.hasGeneratedCaptions,
          baseOptions: captionsStore.baseOptions,
          captionsArea: captionsStore.captionsArea,
          baseCaptionPreset: captionsStore.baseCaptionPreset,
        } as Captions
      } as Project);
    }),
    $reset() {
      editorClipInfoStore.$reset();
      legacyCaptionsStore.$reset();
      useVideoStore().$reset();
      captionsStore.$reset();
      cropsStore.$reset();
      layoutsStore.$reset();
      segmentsStore.$reset();
      stickersStore.$reset();
      effectsStore.$reset();
    }
  }
});
