import { acceptHMRUpdate, defineStore } from 'pinia'
import { retryAsync } from '@/helpers/retry'
import { useGetUploadedVideosQuery } from '@/components/Dialog/MultiUploadDialog/file-uploads/useUploadedVideos'
import * as Sentry from '@sentry/browser'

interface editorClipInfoState extends ClipInfo {
  // Extra info about the clip
  source: string
  isLocalFile: boolean

  // Abort controller is used to cancel the axios request when needed.
  cancellationUploadFileRequest: any

  audioSkipped: boolean
  audioData: AudioBuffer | null

  // Used to signal if we're waiting for clip data. For example the YouTube clip download
  isLoadingClip: boolean

  // Used to strictly define loading state of the project
  loadingState: null | {
    state: 'loading' | 'error';
    title?: string;
    progressRatio?: number;
    description: string;
  }
}

export const useEditorClipInfoStore = defineStore('editorClipInfo', {
  state: (): editorClipInfoState => {
    return {
      id: '',
      title: '',
      mp4Url: '',
      thumbnailUrl: '',
      viewCount: 0,
      languageCode: '',
      broadcasterName: '',
      broadcasterProfileImage: '',
      gameName: '',
      gameBoxArt: '',
      dateFromNow: '',
      source: '',
      isLocalFile: false,

      audioSkipped: false,
      audioData: null,

      cancellationUploadFileRequest: null,

      isLoadingClip: true,
      loadingState: null,
    }
  },
  actions: {
    /**
     * Sets the local ClipInfo state to the given ClipInfo.
     */
    setClip(clipInfo: ClipInfo) {
      this.id = clipInfo.id
      this.title = clipInfo.title
      this.mp4Url = clipInfo.mp4Url
      this.thumbnailUrl = clipInfo.thumbnailUrl
      this.viewCount = clipInfo.viewCount
      this.languageCode = clipInfo.languageCode
      this.broadcasterName = clipInfo.broadcasterName
      this.broadcasterProfileImage = clipInfo.broadcasterProfileImage
      this.gameName = clipInfo.gameName
      this.gameBoxArt = clipInfo.gameBoxArt
      this.dateFromNow = clipInfo.dateFromNow
      this.isLocalFile = clipInfo.isLocalFile
      this.audioData = null
      this.audioSkipped = false
      this.isLoadingClip = false
      this.loadingState = null
    },

    async setClipByUploadId(uploadId: string) {
      await retryAsync(async () => {

        const { suspense, uploadedVideos, error } = useGetUploadedVideosQuery()
        await suspense()

        if (error.value) {
          console.error('Error while refetching uploaded videos', error.value)
          Sentry.captureException(error.value)
          return
        }

        const upload = uploadedVideos.value.find(video => video.id === uploadId)
        if (!upload) {
          console.error('No video found for clip ID', uploadId)
          Sentry.captureException(new Error('No video found for clip ID'))
          return
        }

        this.setClip({
          id: upload.id,
          title: upload.name,
          mp4Url: upload.videoUrl,
          slug: upload.name,
          isLocalFile: true,
          source: 'local-file',
        })
      })
    },
  }
})

export interface ClipInfo {
  id: string
  title: string
  // Can be a URL to R2, a blob URL to localforage or blob URL to a local file.
  mp4Url: string
  thumbnailUrl: string
  viewCount: number
  languageCode: string
  broadcasterName: string
  broadcasterProfileImage: string
  gameName: string
  gameBoxArt: string
  dateFromNow: string
  isLocalFile: boolean
}

// Allows hot-reloading of the store
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useEditorClipInfoStore, import.meta.hot))
}
