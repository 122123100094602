import { defineStore } from 'pinia';
import type { Project } from '@/areas/editor/@type/Project';
import { ref, readonly } from 'vue';
import { noop } from 'lodash-es';
import { useProjectStore } from '@/areas/editor/store/useProjectStore';
import { debouncedSaveProject } from '@/queries/projects/autoSaveProjectById';
import { useApplySnapshot } from '@/areas/editor/store/functions/useApplySnapshot';

export const useHistoryStore = defineStore('history', () => {

  const history: HistoryEntry[] = [];
  const position = ref(0);
  
  const applySnapshot = useApplySnapshot();

  async function undo() {
    
    if (position.value === 0) {
      return;
    }

    const entry = history[position.value - 1];
    const { isCanceled } = await dispatchBeforeUndo(entry.transaction, entry.snapshot);
    if (isCanceled) {
      return;
    }

    position.value--;
    applySnapshot(history[position.value].snapshot);

    await dispatchAfterUndo(entry.transaction, entry.snapshot);
    await debouncedSaveProject();
  }
  
  async function redo() {
    
    if (position.value === history.length - 1) {
      return;
    }

    const entry = history[position.value + 1];
    const { isCanceled } = await dispatchBeforeRedo(entry.transaction, entry.snapshot);
    if (isCanceled) {
      return;
    }

    position.value++;
    applySnapshot(history[position.value].snapshot);
    
    await dispatchAfterRedo(entry.transaction, entry.snapshot);
    await debouncedSaveProject();
  }

  const projectStore = useProjectStore();
  async function transaction(transaction: Transaction, fn: () => MaybePromise<void> = noop) {

    await fn();
    const snapshot = projectStore.project
      ? JSON.parse(JSON.stringify(projectStore.project)) as Project
      : null;
    
    console.debug(transaction, snapshot);

    if (snapshot) {
      history.splice(position.value + 1);
      history.push({ transaction, snapshot });
      position.value = history.length - 1;
      debouncedSaveProject();
    }
  }

  return {

    history: readonly(history),

    canUndo: computed(() => position.value > 0),
    undo: undo,

    canRedo: computed(() => position.value < history.length - 1),
    redo: redo,

    transaction: transaction,
    
    $reset() {
      history.splice(0);
      position.value = 0;
      projectStore.$reset();
    }
  }
});

interface HistoryEntry {
  transaction: Transaction;
  snapshot: Project;
} 

const transactions = {

  'PROJECT:CREATE': 'Create project',
  
  'SEGMENT:UPDATE_DURATION': 'Change segment duration',
  'SEGMENT:UPDATE_START_TIME': 'Change segment start time',
  'SEGMENT:DELETE': 'Delete segment',
  'SEGMENT:SPLIT': 'Split segment',
  
  'LAYOUT:CHANGE': 'Change layout',

  'CAPTIONS:GENERATE': 'Generate captions',
  'CAPTIONS:EDIT_WORD': 'Edit word',
  'CAPTIONS:EDIT_TEXT': 'Edit caption text',
  'CAPTIONS:ADD': 'Add caption',
  'CAPTIONS:MOVE': 'Move caption',
  'CAPTIONS:RESIZE': 'Resize caption',
  'CAPTIONS:REGENERATE': 'Regenerate captions',
  'CAPTIONS:RESET': 'Reset captions',
  'CAPTIONS:DELETE': 'Delete caption',
  'CAPTIONS:DELETE_ALL': 'Delete all captions',
  'CAPTIONS:CHANGE_EMOJI': 'Change emoji',
  'CAPTIONS:ADD_EMOJI': 'Add emoji',
  'CAPTIONS:DELETE_EMOJI': 'Delete emoji',
  'CAPTIONS:DELETE_WORDS': 'Delete word(s)',
  'CAPTIONS:REMOVE_EFFECTS': 'Remove caption effects',
  'CAPTIONS:CHANGE_COLOR': 'Change color',
  'CAPTIONS:MERGE': 'Merge captions',
  'CAPTIONS:CHANGE_ANIMATION_TIMING': 'Change animation timing',
  'CAPTIONS:CHANGE_ANIMATION_TARGET': 'Change animation target',
  'CAPTIONS:CHANGE_ANIMATION_GROUPING': 'Change animation grouping',
  'CAPTIONS:CHANGE_ANIMATION': 'Change animation',
  'CAPTIONS:TOGGLE_EMOJIS': 'Toggle emojis',
  'CAPTIONS:CHANGE_STROKE_WIDTH': 'Change stroke width',
  'CAPTIONS:CHANGE_BACKGROUND_OPACITY': 'Change background opacity',
  'CAPTIONS:TOGGLE_ROTATION': 'Toggle rotation',
  'CAPTIONS:TOGGLE_STRIP_PUNCTUATION': 'Toggle strip punctuation',
  'CAPTIONS:ADD_SHAKE_EFFECT': 'Add shake effect',
  'CAPTIONS:ADD_SUPERSIZE_EFFECT': 'Add supersize effect',

  'WORD:UPDATE_DURATION': 'Change word duration',
  'WORD:UPDATE_START_TIME': 'Change word start time',
  
  'STICKER:MOVE_TO_FOREGROUND': 'Move sticker to foreground',
  'STICKER:MOVE_TO_BACKGROUND': 'Move sticker to background',
  'STICKER:MOVE_FORWARD': 'Move sticker forward',
  'STICKER:MOVE_BACKWARD': 'Move sticker backward',
  'STICKER:MOVE': 'Move sticker',
  'STICKER:RESIZE': 'Resize sticker',
  'STICKER:UPDATE_DURATION': 'Change sticker duration',
  'STICKER:UPDATE_START_TIME': 'Change sticker start time',
  'STICKER:DELETE': 'Delete sticker',
  'STICKER:EDIT_TEXT': 'Edit sticker text',
  'STICKER:EDIT_COLOR': 'Edit sticker color',
  'STICKER:ADD': 'Add sticker',
  'STICKER:SPLIT': 'Split sticker',

  'CROP:MOVE_TO_FOREGROUND': 'Move crop to foreground',
  'CROP:MOVE_TO_BACKGROUND': 'Move crop to background',
  'CROP:MOVE_FORWARD': 'Move crop forward',
  'CROP:MOVE_BACKWARD': 'Move crop backward',
  'CROP:MOVE': 'Move crop',
  'CROP:RESIZE': 'Resize crop',
  'CROP:DELETE': 'Delete crop',
  'CROP:ADD': 'Add crop',
  'CROP:CHANGE_SHAPE': 'Change crop shape',
  
  'FEED:RESIZE': 'Resize preview crop',
  'FEED:MOVE': 'Move preview crop',
  
  'SOUND_EFFECT:CHANGE_VOLUME': 'Change sound effect volume',
  'SOUND_EFFECT:ADD': 'Add sound effect',
  'SOUND_EFFECT:DELETE': 'Delete sound effect',
  'SOUND_EFFECT:UPDATE_DURATION': 'Change sound effect duration',
  'SOUND_EFFECT:UPDATE_START_TIME': 'Change sound effect start time',
  'SOUND_EFFECT:SPLIT': 'Split sound effect',
  
  'ZOOM:ADD': 'Add zoom',
  'ZOOM:UPDATE_DURATION': 'Change zoom duration',
  'ZOOM:UPDATE_START_TIME': 'Change zoom start time',
  'ZOOM:MOVE': 'Move zoom',
  'ZOOM:DELETE': 'Delete zoom',
  'ZOOM:SPLIT': 'Split zoom',
} as const satisfies Record<string, string>;

type Transaction = keyof typeof transactions;
type MaybePromise<T> = T | Promise<T>;

type HistoryEventCallback = (snapshot: Project) => MaybePromise<boolean | void>;

type HistoryEventListener = {
  transaction: Transaction;
  callback: HistoryEventCallback;
}

async function dispatchAsync(listeners: Set<HistoryEventListener>, transaction: Transaction, snapshot: Project) {
  
  for (const listener of listeners) {

    if (listener.transaction !== transaction) {
      continue;
    }

    const accepted = await listener.callback(snapshot);
    if (accepted === false) {
      return { isCanceled: true };
    }
  }

  return { isCanceled: false };
}

const beforeUndoListeners = new Set<HistoryEventListener>();
export function onBeforeUndo(transaction: Transaction, callback: HistoryEventCallback) {
  beforeUndoListeners.add({ transaction, callback });
  return () => beforeUndoListeners.delete({ transaction, callback });
}

async function dispatchBeforeUndo(transaction: Transaction, snapshot: Project) {
  return dispatchAsync(beforeUndoListeners, transaction, snapshot);
}

const afterUndoListeners = new Set<HistoryEventListener>();
export function onAfterUndo(transaction: Transaction, callback: HistoryEventCallback) {
  afterUndoListeners.add({ transaction, callback });
  return () => afterUndoListeners.delete({ transaction, callback });
}

async function dispatchAfterUndo(transaction: Transaction, snapshot: Project) {
  return dispatchAsync(afterUndoListeners, transaction, snapshot);
}

const beforeRedoListeners = new Set<HistoryEventListener>();
export function onBeforeRedo(transaction: Transaction, callback: HistoryEventCallback) {
  beforeRedoListeners.add({ transaction, callback });
  return () => beforeRedoListeners.delete({ transaction, callback });
}

async function dispatchBeforeRedo(transaction: Transaction, snapshot: Project) {
  return dispatchAsync(beforeRedoListeners, transaction, snapshot);
}

const afterRedoListeners = new Set<HistoryEventListener>();
export function onAfterRedo(transaction: Transaction, callback: HistoryEventCallback) {
  afterRedoListeners.add({ transaction, callback });
  return () => afterRedoListeners.delete({ transaction, callback });
}

async function dispatchAfterRedo(transaction: Transaction, snapshot: Project) {
  return dispatchAsync(afterRedoListeners, transaction, snapshot);
}
  
