
export const DefaultRiveUrl = '/stickers_centerd.riv';

export const DecodeImage = async (url: string) => {
    const image = new Image();
    image.src = url;
    await image.decode();
    const bitmap = await createImageBitmap(image);
    return bitmap;
}


export const RiveArtboards = {
    TwitchFollow: '30',
    YoutubeNotification: '31 2',
    TiktokFollow: '32',
}

export const RiveDefinitions: {
    [key: string]: {
        xOffset?:  number
        widthOffset?: number
        yOffset?: number
        heightOffset?: number
        defaultStateMachine?: [string, number][]
        xOriginOffset?: number
        soundEffect?: string
        animationDuration?: number
        minWidth?: number
    }
} = {
    [RiveArtboards.TwitchFollow]: {
        xOffset: -110,
        yOffset: 20,
        defaultStateMachine: [['Number 1', 1]],
        soundEffect: '/audio-effects/sounds/sticker_artboard_30.mp3',
        animationDuration: 2
    },
    [RiveArtboards.YoutubeNotification]: {
        xOffset: 20,
        widthOffset: 1,
        heightOffset: 100,
        xOriginOffset: 0,
        yOffset: 20,
        soundEffect: '/audio-effects/sounds/sticker_artboard_31.mp3',
        defaultStateMachine: [['Number 1', 1]],
        animationDuration: 3,
    },
    [RiveArtboards.TiktokFollow]: {
        xOffset: 4,
        yOffset: -40,
        widthOffset: 20,
        heightOffset: 200,
        minWidth: 250,
        defaultStateMachine: [['Number 1', 1]],
        soundEffect: '/audio-effects/sounds/sticker_artboard_32.mp3',
        animationDuration: 2
    },
}