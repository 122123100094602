import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useSLVideoController } from '@/modules/SLVideoplayer/hooks/useSLVideoController'
import { useEditorMainStore } from '@/store/editor/editorMain'
import { gsap } from 'gsap'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { useRafFn, useMediaControls } from '@vueuse/core'
import * as Sentry from '@sentry/vue'
import MP4Box from 'mp4box';
import { logFlatTable } from '@/lib/console';

export const _globalTimeline = gsap.timeline({
  repeat: -1,
  repeatDelay: 0,
  paused: true,
})

export const useVideoStore = defineStore('video', () => {

  const videoElement = ref<HTMLVideoElement>()
  const src = ref('')

  const segmentsStore = useSegmentsStore()
  const segments = segmentsStore.whereIsNotZoom()

  const videoSize = ref<{ width: number, height: number } | null>(null)

  const videoController = useSLVideoController(videoElement, segments)
  
  const audioElement = ref<HTMLAudioElement>()
  const audioControls = useMediaControls(audioElement)


  function handleDocumentVisibilityChange() {
    if (document.hidden) {
      playing.value = false;
      preservedPaused.value = true;
      videoElement.value?.pause();
    }
  }

  window.addEventListener("visibilitychange", handleDocumentVisibilityChange);
  
  const playing = computed({
    get() {
      if (audioControls.playing.value !== videoController.playing.value) {
        audioControls.playing.value = videoController.playing.value
      }
      return audioControls.playing.value && videoController.playing.value
    },
    set(value) {
      audioControls.playing.value = value
      videoController.playing.value = value
    }
  })

  const canvas = ref<OffscreenCanvas | null>(null)

  useRafFn(() => {
    if (videoElement.value) {
      canvas.value
        ?.getContext('2d')
        ?.drawImage(videoElement.value, 0, 0, canvas.value.width, canvas.value.height)
    }
  })

  function resizeCanvas(width: number, height: number) {
    if (canvas.value) {
      canvas.value.width = Math.abs(Math.round(width))
      canvas.value.height = Math.abs(Math.round(height))
    }
  }

  const preservedPaused = ref(false)

  const preservedMuted = ref(true)

  watch(audioControls.muted, (muted) => {
    preservedMuted.value = muted
    if (videoElement.value) videoElement.value.muted = muted;
  })

  const scrubbing = ref(false)

  const isLoaded = computed(() => {
    return videoElement.value !== undefined
  })

  function getExactTime() {
    return (videoElement.value?.currentTime ?? 0) * 1000
  }

  async function loadVideo(element: HTMLVideoElement, audio: HTMLAudioElement, _src: string) {

    return await new Promise<void>((resolve, reject) => {

      videoElement.value = element;
      audioElement.value = audio;
      
      // Ensure new canvas is created for each video
      canvas.value = null;

      const isVideoInvalid = () => {
        const hasInvalidDuration = isNaN(element.duration) || !isFinite(element.duration);
        const hasInvalidDimensions = element.videoWidth === 0 || element.videoHeight === 0 || isNaN(element.videoWidth) || isNaN(element.videoHeight);
        return { hasInvalidDuration, hasInvalidDimensions };
      }

      function handleVideoState() {

        const { hasInvalidDuration, hasInvalidDimensions } = isVideoInvalid();

        if (!hasInvalidDuration && !hasInvalidDimensions) {
          cleanup();
          resolve();
        } else if (element.readyState >= HTMLMediaElement.HAVE_CURRENT_DATA) {
          cleanup();
          console.error(JSON.stringify(element));
          const error = new Error(hasInvalidDuration ? 'Video has no duration' : 'Video has no dimensions');
          Sentry.captureException(error);
          reject(error);
        }
      }
      
      function handleError(event: Event) {
        console.error(JSON.stringify(event));
        Sentry.captureException(new Error('Video failed to load'));
        handleVideoState()
      }

      function cleanup() {
        element.removeEventListener('error', handleError);
        element.removeEventListener('loadedmetadata', handleVideoState);
        element.removeEventListener('loadeddata', handleVideoState);
        element.removeEventListener('canplay', handleVideoState);
        element.removeEventListener('canplaythrough', handleVideoState);
      }

      element.addEventListener('error', handleError);
      element.addEventListener('loadedmetadata', handleVideoState);
      element.addEventListener('loadeddata', handleVideoState);
      element.addEventListener('canplay', handleVideoState);
      element.addEventListener('canplaythrough', handleVideoState);

      audioControls.muted.value = preservedMuted.value;
      videoController.muted.value = preservedMuted.value;
      src.value = _src;
    }).then(() => {
      console.log('Video loaded:', element.src);
      videoSize.value = { width: element.videoWidth, height: element.videoHeight };
      canvas.value = new OffscreenCanvas(element.videoWidth, element.videoHeight);
      setMainStore(element);
      findFramerateOf(src.value).then(fps => {
        console.log('Framerate:', fps);
        framerate.value = fps;
      })
    });
  }

  const framerate = ref(60)
  async function findFramerateOf(src: string): Promise<number> {

    const blob = await fetch(src)
      .then((r) => r.blob())
      .catch((e) => {
        console.error(e)
        throw new Error('Could not fetch video file. Please try again.')
      })

    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      const mp4boxFile = MP4Box.createFile();

      type Track = {
        type: 'video' | 'audio',
        duration: number,
        timescale: number,
        nb_samples: number,
      }

      mp4boxFile.onReady = (info: { tracks: Track[] }) => {
        // Assume the first video track contains the framerate info
        const videoTrack = info.tracks.find(track => track.type === 'video');

        logFlatTable(info)

        if (videoTrack && videoTrack.duration && videoTrack.timescale && videoTrack.nb_samples) {
          const framerate = videoTrack.nb_samples / (videoTrack.duration / videoTrack.timescale);
          resolve(framerate);
        } else {
          reject(new Error('No video track or insufficient data found'));
        }
      };

      fileReader.onload = () => {
        // Add `fileStart` property to arrayBuffer by type assertion
        const arrayBuffer = fileReader.result as ArrayBuffer & { fileStart: number };
        arrayBuffer.fileStart = 0;
        mp4boxFile.appendBuffer(arrayBuffer);
        mp4boxFile.flush();
      };

      fileReader.onerror = () => reject(fileReader.error);
      fileReader.readAsArrayBuffer(blob);
    });
  }

  function setMainStore(element: HTMLVideoElement) {
    const editorMainStore = useEditorMainStore()
    if (editorMainStore.videoHeight === 0 || editorMainStore.trimmedEndTime === 0) {
      // editorMainStore.videoDuration = Math.round(element.duration * 1000)
      editorMainStore.videoHeight = element.videoHeight
      editorMainStore.videoWidth = element.videoWidth
    }
  }

  function unmountElement() {
    // When the video or audio element is removed from the DOM, the src attribute should be set to an empty string to allow garbage collecting.
    // https://html.spec.whatwg.org/multipage/media.html#best-practices-for-authors-using-media-elements

    if (videoElement.value) {
      videoElement.value.pause()
      videoElement.value.src = ''
      videoElement.value.load()
      videoElement.value = undefined
      src.value = ''
    }

    if (audioElement.value) {
      audioElement.value.pause()
      audioElement.value.src = ''
      audioElement.value.load()
      audioElement.value = undefined
    }
  }

  return {
    ...videoController,
    playing,
    volume: audioControls.volume,
    muted: audioControls.muted, 
    durationMs: computed(() => videoController._duration.value * 1000),
    canvas,
    resizeCanvas,
    segments,
    loadVideo,
    getExactTime,
    videoElement,
    audioElement,
    videoSize,
    scrubbing,
    isLoaded,
    unmountElement,
    $reset() {
      unmountElement();
      canvas.value = null;
      src.value = null;
    },
    preservedPaused,
    framerate,
    // timeline: timeline,
  }
})
