import type { LoggingAdapter } from '@/logging/index'
import { posthog } from 'posthog-js'
import type { Properties, PostHog as PostHogInstance } from 'posthog-js'
import { nextTick, type App } from 'vue'
import { getIdentity } from '@/logging/identyService'

export const PostHog: LoggingAdapter<Properties | null | undefined> & { instance: null | PostHogInstance } = {
  instance: null,

  initialize(app) {
    if (Boolean(Number(import.meta.env.VITE_POSTHOG_ENABLED)) || import.meta.env.PROD) {
      app.use(PostHogPlugin)
      this.instance = app.config.globalProperties.$posthog
      console.log('[@posthog/initialized]')
    }
  },

  trackEvent(eventName, properties) {
    if (this.instance) {
      this.instance.capture(eventName, properties)
    } else {
      console.log('[@posthog/trackEvent]', eventName, properties)
    }
  },

  async pageView(to, from, failure) {
    if (this.instance) {
      if (!failure) {
        await nextTick()
        this.instance.capture('$pageview', {
          page_title: to.name?.toString(),
          page_path: to.path,
        })
      }
    } else {
      console.log('[@posthog/pageView]', to.fullPath)
    }
  },

  reset() {
    if (this.instance) {
      this.instance.reset()
    } else {
      console.log('[@posthog/reset]')
    }
  },

  registerUser(user) {
    const userPropertiesToSet = getIdentity(user)

    const tier = user.tier

    if (this.instance) {
      // add group based on user subscription tier
      this.instance.group('tier', tier.toString())
      this.instance.identify(user.id, userPropertiesToSet)
    } else {
      console.log('[@posthog/registerUser]', user.id, userPropertiesToSet)
    }
  },

  identifyUser() {
    // Not implemented, but is called
  },
}

const PostHogPlugin = {
  install(app: App) {
    app.config.globalProperties.$posthog = posthog.init(import.meta.env.VITE_POSTHOG_API_KEY as string, {
      api_host: import.meta.env.VITE_POSTHOG_API_HOST as string,
      ui_host: import.meta.env.VITE_POSTHOG_UI_HOST as string,
      capture_pageview: false,
      autocapture: false,
      opt_in_site_apps: true,
      disable_web_experiments: false,
    })
    app.provide('posthog', posthog)
  },
}
