import { acceptHMRUpdate, defineStore } from 'pinia'
import { accountsAxios } from '@/services/axios'
import * as Sentry from '@sentry/browser'
import logging from '@/logging'
import { tiers } from '@/enums/tiers'
import type { TrialStates } from '@/apis/streamladder-accounts/model'
import type { ConnectionType } from '@/enums/connectionTypes'
import connectionTypes from '@/enums/connectionTypes'
import { retryAsync } from '@/helpers/retry'
import EventBus from '@/eventBus'
import authEvents from '@/events/authEvents'
import { storeLoggedInStateCookie } from '@/store/user/storeLoggedInStateCookie'
import { getUser, getSession } from '@/authentication/supabase'
import { mbToBytes, gbToBytes } from '@/helpers/fileSize'
import { dedupeAsync } from '@/helpers/dedupeAsync'

export interface UserInfoState {
  isAuthenticated: boolean
  possiblyAuthenticated: boolean
  userName: string
  email: string
  hasSubscription: boolean
  tier: number
  // TODO plan can be a getter
  plan: string
  userId: string
  hasLoadedUserInfo: boolean
  createdAt: Date | null

  referralCode: string
  trialStatus: TrialStates
  trialEndDate: Date | null

  googleAccount: GoogleAccount | null
  twitchAccount: TwitchAccount | null

  tikTokAccount: TikTokAccount | null
  tikTokAccounts: TikTokAccount[]

  youTubeAccount: YouTubeAccount | null
  youTubeAccounts: YouTubeAccount[]

  instagramAccounts: InstagramAccount[]

  failedToReachAccountsService: boolean
}

export const useUserInfoStore = defineStore('userInfo', {
  state: (): UserInfoState => {
    return {
      isAuthenticated: false,
      // possiblyAuthenticated is used to show optimistic UI and reduce flickering
      possiblyAuthenticated: false,
      userName: '',
      email: '',
      hasSubscription: false,
      tier: 0,
      plan: 'Free',
      userId: '',
      hasLoadedUserInfo: false,
      createdAt: null,

      referralCode: '',
      trialStatus: 'available',
      trialEndDate: null,

      googleAccount: null,
      twitchAccount: null,
      tikTokAccount: null, // TODO is this still used?
      tikTokAccounts: [],
      youTubeAccount: null, // TODO is this still used?
      youTubeAccounts: [],
      instagramAccounts: [],

      failedToReachAccountsService: false,
    }
  },
  getters: {
    fileSizeLimit: (state: UserInfoState) => {
      return selectFileSizeLimitForTier(state.tier)
    },
    connections: (state: UserInfoState) => {
      return {
        youtube: state.youTubeAccounts,
        tiktok: state.tikTokAccounts.filter((a) => a.state !== 'inactive'), // Filter out inactive accounts
        instagram: state.instagramAccounts,
      }
    },
    allSocials: (state: UserInfoState): TypedOutboundSocialAccount[] => {
      return [
        ...state.youTubeAccounts.map((a) => ({ ...a, type: connectionTypes.YOUTUBE })),
        ...state.tikTokAccounts.map((a) => ({ ...a, type: connectionTypes.TIKTOK })),
        ...state.instagramAccounts.map((a) => ({ ...a, type: connectionTypes.INSTAGRAM })),
      ]
    },
    findSocialById() {
      return (id: string): TypedOutboundSocialAccount | undefined => {
        return this.allSocials.find((a) => a.id === id)
      }
    },
    hasTwitchConnected: (state: UserInfoState) => state.twitchAccount != null,
    hasGoogleConnected: (state: UserInfoState) => state.googleAccount != null,
    isLoggedIn: (state: UserInfoState) => state.isAuthenticated,
    trialDaysLeft: (state: UserInfoState) => {
      if (!state.trialEndDate) return 0
      const now = new Date()
      const diff = state.trialEndDate.getTime() - now.getTime()
      return Math.ceil(diff / (1000 * 60 * 60 * 24))
    },
    trialActive: (state: UserInfoState) => state.trialStatus === 'active',
  },
  actions: {
    /**
     * Loads the user's info from the API, and if it fails set the user as Anonymous.
     */
    async updateUserInfo() {
      
      const { data: { session } } = await getSession()
      if (!session) {
        storeLoggedInStateCookie(false)
        this.setUserAnonymous()
        this.hasLoadedUserInfo = true
        return
      }

      // Get user info from the API
      const previousState = { ...this.$state }
      const response = await fetchApiUser()
      if (!response) {
        this.failedToReachAccountsService = true
        // Short-circuit. An error / notice will be shown to the user in `UserEndpointLoader.vue`.
        return;
      }

      const nextState = response.data

      storeLoggedInStateCookie(response.data?.isAuthenticated)
      if (response.data?.isAuthenticated) {
        logging.identifyUser(response.data?.id)
        logging.registerUser(response.data)
        Sentry.addBreadcrumb({
          category: 'auth',
          message: 'Authenticated user: ' + response.data?.userName,
          level: 'info',
        })

        this.setUser(response.data)

        this.hasLoadedUserInfo = true

        if (previousState.isAuthenticated === nextState.isAuthenticated 
          && previousState.userId === nextState.id
        ) {
          // No need to update the store if the user info hasn't changed
          console.log('User info has not changed')
          return
        }
      } else {
        logging.identifyUser(response.data?.id)
        this.setUserAnonymous()
        this.hasLoadedUserInfo = true
      }
    },
    /**
     * Sets the current user as Anonymous.
     * TODO can probably be merged into updateUserInfo.
     */
    setUserAnonymous() {
      const userData = {
        userName: '',
        email: '',
        isAuthenticated: false,
        userId: '',
        tier: 0,
      }
      this.setUser(userData)
    },
    /**
     * Loads the user's info from the API payload.
     * @param payload
     */
    setUser(payload) {
      this.userName = payload.userName
      this.email = payload.email
      this.isAuthenticated = payload.isAuthenticated
      this.possiblyAuthenticated = payload.isAuthenticated
      this.hasSubscription = payload.tier > 0
      this.plan = tiers.toString(payload.tier)
      this.tier = payload.tier
      this.userId = payload.id
      this.referralCode = payload.referralCode
      this.createdAt = new Date(payload.createdAt)

      this.trialStatus = payload.trialState?.state
      this.trialEndDate = new Date(payload.trialState?.endDate)

      this.googleAccount = payload.googleAccount
      this.twitchAccount = payload.twitchAccount
      this.tikTokAccount = payload.tikTokAccount
      this.tikTokAccounts = payload.tikTokAccounts || []
      this.youTubeAccount = payload.youTubeAccount
      this.youTubeAccounts = payload.youTubeAccounts || []
      this.instagramAccounts = payload.instagramAccounts || []

      if (this.userName == '') {
        Sentry.setUser(null)
      } else {
        Sentry.setUser({
          id: this.userId,
          email: this.email,
          username: this.userName,
        })
      }
    },
    /**
     * Saves the new Username to the API and updates the store.
     */
    updateDisplayName(name: string) {
      this.userName = name
    },

    /**
     * Saves the new Email to the API and updates the store.
     */
    updateEmail(email: string) {
      this.email = email
    },

    ensureLoggedInWithDialog(): Promise<boolean> {
      return new Promise<boolean>((resolve) => {
        if (!this.isLoggedIn) {
          EventBus.$emit(authEvents.OPEN_LOGIN_DIALOG, {
            callback: () => resolve(this.isLoggedIn),
          })
        } else {
          resolve(true)
        }
      })
    },
  },
})

export interface OutboundSocialAccount {
  id: string
  displayName: string
  hasAccess: boolean
  profileImageUrl: string
  state: 'active' | 'inactive' | 'unathorized' | 'disconnected'
  scopes: string
  updatedAt: string
}

export type TypedOutboundSocialAccount = OutboundSocialAccount & { type: ConnectionType }

// TODO Get this from the API docs
export interface GoogleAccount {}

export interface TwitchAccount {}

export type TikTokAccount = OutboundSocialAccount

export type YouTubeAccount = OutboundSocialAccount

export type InstagramAccount = OutboundSocialAccount

export type SocialMediaAccount = { type: ConnectionType } & OutboundSocialAccount

// Allows hot-reloading of the store
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useUserInfoStore, import.meta.hot))
}

export function onUserInfoReady(callback: (userInfo: UserInfoState) => void) {
  const store = useUserInfoStore()
  if (store.hasLoadedUserInfo) {
    callback(store.$state)
  }
  store.$subscribe((mutation, state) => {
    if (mutation.type === 'direct' && state.hasLoadedUserInfo) {
      callback(state)
    }
  })
  store.updateUserInfo().catch(console.error)
}

export async function onUserInfoReadyAsync(): Promise<UserInfoState> {
  return new Promise((resolve) => onUserInfoReady(resolve))
}

export function onLoggedIn(callback: () => void) {
  const store = useUserInfoStore()
  if (store.isAuthenticated) {
    callback()
  }
  store.$subscribe((mutation, state) => {
    if (mutation.type === 'direct' && state.isAuthenticated) {
      callback()
    }
  })
}

export async function onLoggedInAsync(): Promise<void> {
  return new Promise((resolve) => onLoggedIn(resolve))
}

export function selectFileSizeLimitForTier(tier: number): number {
  switch (tier) {
    case tiers.GOLD:
      return gbToBytes(2)
    case tiers.SILVER:
      return gbToBytes(1)
    case tiers.FREE:
    default:
      return mbToBytes(200)
  }
}

const fetchApiUser = dedupeAsync(async () => {
  try {
    return await retryAsync(async () => {
      const response = await accountsAxios.get<ApiUser>('/api/user')
      if (response.status === 200) {
        return response
      } else if (response.status === 500) {
        // If a server error happens in the accounts service, we should show a notice to the user as soon as possible.
        return null
      } else {
        // In case of other errors, we should retry first before showing a notice.
        throw new Error('Failed to fetch user')
      }
    })
  } catch (e) {
    console.error(e)
    return null
  }
})

export interface ApiUser {
  id: string
  userName: string
  email: string
  tier: number
  isAuthenticated: boolean
  googleAccount: GoogleAccount
  twitchAccount: TwitchAccount
  tikTokAccount: TikTokAccount
  tikTokAccounts: TikTokAccount[]
  youTubeAccount: YouTubeAccount
  youTubeAccounts: YouTubeAccount[]
  instagramAccounts: InstagramAccount[]
  referralCode: string
  trialState: {
    endDate: string
    state: TrialStates
  }
}
